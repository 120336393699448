@import '../../../styles/monitor-resolution.scss';

.ThemeBubble {
  max-width: 320px;
  min-height: 50px;
  background-color: #209BC1;
  border-radius: 15px;
  color: white;
  padding: 20px;
  margin: 20px 20px;
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    min-height: 170px;
  }

  &-textContainer {
    height: 100%;
    display: flex;
  }

  &-text {
    padding: 5px;
    font-weight: bold;
    text-align: left;
    font-size: 16px;

    @media ($mqSmallMobiles) {
      font-size: 18px;
    }

    @media ($mqMobiles) {
      font-size: 20px;
    }
  }
}
