@import '../../../styles/monitor-resolution.scss';

.SiteSwitchButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &-header {
    margin-right: 10px;
    font-weight: bold;
    color: white;
    font-size: 16px;
  }

  &-linkButton {
    border-radius: 5px;
    padding: 1px 5px;
    margin: 0 3px;
    background-color: transparent;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    color: #209bc1;
    border: 2px solid transparent;
    font-weight: bold;
    font-size: 16px;

    &--actual {
      border: 2px solid white;
      background-color: white;
      &:hover {
        cursor: pointer;
      }
    }

    &--clickable {
      &:hover {
        color: white;
        border: 2px solid white;
        cursor: pointer;
      }
    }
  }
}
