.App {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  font-family: 'Ronnia Italic', sans-serif !important;

  html {
    scroll-behavior: smooth !important;
  }

  text-align: center;

  // &-logo {
  //   height: 40vmin;
  //   pointer-events: none;
  // }

  // @media (prefers-reduced-motion: no-preference) {
  //   &-logo {
  //     animation: App-logo-spin  20s linear;
  //   }
  // }

  // &-header {
  //   background-color: #282c34;
  //   min-height: 100vh;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: calc(10px + 2vmin);
  //   color: white;
  // }

  // &-link {
  //   color: #61dafb;
  // }

  // @keyframes App-logo-spin {
  //   from {
  //     transform: rotate(0deg);
  //   }
  //   to {
  //     transform: rotate(360deg);
  //   }
  // }
}
