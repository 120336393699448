@import '../../../styles/monitor-resolution.scss';

.CountDown {
  width: 100%;
  padding: 20px 20px;
  background-color: #209bc1;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-header {
    font-size: 20px;
    font-weight: bold;

    @media ($mqSmallMobiles) {
      font-size: 26px;
    }

    @media ($mqMobiles) {
      font-size: 32px;
    }
  }

  &-container {
    display: flex;
    flex-direction: row;
  }

  &-valueContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  &-value {
    font-size: 40px;
    font-weight: bold;

    @media ($mqSmallMobiles) {
      font-size: 50px;
    }

    @media ($mqMobiles) {
      font-size: 60px;
    }
  }

  &-valueText {
    font-size: 10px;

    @media ($mqSmallMobiles) {
      font-size: 14px;
    }

    @media ($mqMobiles) {
      font-size: 18px;
    }
  }

  &-pulsation {
    position: static;
    background: transparent;
    margin: 50px 0 20px 0;
    height: 50px;
    width: 200px;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
}
