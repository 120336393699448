.SideDrawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1500000;
  height: 100vh;
  width: 70%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

  @media (min-width: 400px) {
    width: 50%;
  }
}
