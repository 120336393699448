@import '../../../styles/monitor-resolution.scss';

@keyframes extend {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.SloganSlide {
  width: 100%;

  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ($mqMobiles) {
      display: flex;
      flex-direction: row;
    }
  }

  &-word1 {
    padding: 20px;
    opacity: 0;
    animation: fadeIn 1.5s ease-in forwards;
    animation-delay: 0;
    font-size: 20px;
    font-weight: bold;
    color: #209bc1;

    @media ($mqSmallMobiles) {
      font-size: 26px;
    }

    @media ($mqMobiles) {
      font-size: 32px;
    }
  }

  &-word2 {
    padding: 20px;
    opacity: 0;
    animation: fadeIn 1.5s ease-in forwards;
    animation-delay: 1.5s;
    font-size: 20px;
    font-weight: bold;
    color: #209bc1;

    @media ($mqSmallMobiles) {
      font-size: 26px;
    }

    @media ($mqMobiles) {
      font-size: 32px;
    }
  }

  &-word3 {
    padding: 20px;
    opacity: 0;
    animation: fadeIn 1.5s ease-in forwards;
    animation-delay: 3s;
    font-size: 20px;
    font-weight: bold;
    color: #209bc1;

    @media ($mqSmallMobiles) {
      font-size: 26px;
    }

    @media ($mqMobiles) {
      font-size: 32px;
    }
  }

  &-button {
    border-radius: 50%;
    margin: 25px 0 20px 0;
    fill: #209bc1;
    padding: 3px;
    opacity: 0;
    animation: fadeIn 1.5s forwards, pulse 1.5s infinite forwards;
    animation-delay: 4.5s;

    &:hover {
      border-radius: 50%;
      background-color: #209bc1;
      fill: white;
      cursor: pointer;
    }
  }
}
