@import '../../../styles/monitor-resolution.scss';

@import "~react-image-gallery/styles/scss/image-gallery.scss";

.Gallery24 {
  width: 100%;
  padding: 20px 20px;
  background-color: #209bc1;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  // &--white {
  //   background-color: white;
  //   color: #585858;
  // }

  &-header {
    font-size: 20px;
    font-weight: bold;

    @media ($mqSmallMobiles) {
      font-size: 26px;
    }

    @media ($mqMobiles) {
      font-size: 32px;
    }
  }

  &-container {
    width: 100%;
    margin: 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-advertisement {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-advertisementLogo {
    padding: 30px 30px;
    display: inline-block;
    width: 260px;
    height: 100%;

    &:hover {
      transform: scale(1.2);
    }

    @media ($mqSmallMobiles) {
      width: 320px;
    }

    @media ($mqMobiles) {
      width: 400px;
    }
  }

  &-content {
    max-width: 1200px;
    min-height: 340px;
    padding: 20px;
    border: 2px solid #585858;
    background-color: #585858;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-videoElement {
    padding: 0 20px 0 20px;
  }

  &-videoElementHeader {
    padding: 0 0 30px 0;
    // color: white;
  }
}
