@import '../../../styles/monitor-resolution.scss';

.GoalCard24 {
  position: relative;
  width: 320px;
  height: 240px;
  background-color: white;
  border-radius: 15px;
  margin: 20px 20px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  @media ($mqSmallMobiles) {
    height: 320px;
  }

  @media ($mqMobiles) {
    height: 440px;
  }

  &-id {
    color: #209bc1;
    margin-right: 10px;
    font-weight: bold;
    font-size: 28px;

    @media ($mqSmallMobiles) {
      font-size: 34px;
    }

    @media ($mqMobiles) {
      font-size: 40px;
    }
  }

  &-textContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-text {
    padding: 15px;
    font-weight: bold;
    text-align: left;
    font-size: 16px;

    @media ($mqSmallMobiles) {
      font-size: 18px;
    }

    @media ($mqMobiles) {
      font-size: 22px;
    }
  }

  &:hover {
    transform: rotateY(180deg);
  }

  &-front,
  &-back {
    position: absolute;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }

  &-front {
    background-color: white;
    color: black;
  }

  &-back {
    background-color: white;
    color: #585858;
    transform: rotateY(180deg);
  }

  &-rightArrow {
    fill: #209bc1;
    padding: 3px;
    opacity: 0;
    animation: fadeIn 1.5s forwards, pulse 2s linear 0s infinite forwards;
  }
}
