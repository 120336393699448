@import '../../../styles/monitor-resolution.scss';

.Contact {
  width: 100%;
  padding: 20px 20px 40px 20px;
  background-color: #4f4c4c;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-header {
    font-size: 20px;
    font-weight: bold;

    @media ($mqSmallMobiles) {
      font-size: 26px;
    }

    @media ($mqMobiles) {
      font-size: 32px;
    }
  }

  &-announcementContainer {
    text-decoration: none !important;
    color: white;
  }

  &-socialMediaContainer {
    display: flex;
    flex-direction: row;
    margin: 10px 0 0 0;
  }

  @keyframes extend {
    from {
      transform: scale(1.0);
    }
    to {
      transform: scale(1.2);
    }
  }

  &-socialMediaLogo {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    width: 32px;
    height: 32px;

    &:hover {
      animation: extend 0.3s ease-in 0s 1 normal forwards;
    }

    @media ($mqSmallMobiles) {
      width: 38px;
      height: 38px;
    }

    @media ($mqMobiles) {
      width: 44px;
      height: 44px;
    }
  }
}
