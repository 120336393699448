.MenuButton {
  &-link {
    text-decoration: none;
    color: inherit;
    border: solid 2px transparent;
    border-radius: 10px;
    position: relative;
    font-size: 16px;
    padding: 3px;

    &:hover {
      border: solid 2px #585858;
    }
  }

  &-listElement {
    margin: 3px;
    display: inline-block;
    white-space: nowrap;
    color: #585858;
    font-weight: bold;
    border: none;
    cursor: pointer;
  }
}
