@import '../../../styles/monitor-resolution.scss';

.Program {
  max-width: 1150px;
  padding: 20px 20px;
  background-color: white;
  color: #585858;

  &-header {
    font-size: 20px;
    font-weight: bold;
    color: #585858;

    @media ($mqSmallMobiles) {
      font-size: 26px;
    }

    @media ($mqMobiles) {
      font-size: 32px;
    }
  }

  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-interval {
    color: #209bc1;
    font-weight: bold;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;

    @media ($mqSmallMobiles) {
      font-size: 16px;
    }

    @media ($mqMobiles) {
      font-size: 20px;
    }
  }

  &-description {
    text-align: left;
    padding-left: 30px;
    color: #585858;
    font-weight: bold;
    font-size: 12px;

    @media ($mqSmallMobiles) {
      font-size: 16px;
    }

    @media ($mqMobiles) {
      font-size: 20px;
    }
  }

  &-announcementContainer {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-top: 10px;

    @media ($mqSmallMobiles) {
      font-size: 16px;
    }

    @media ($mqMobiles) {
      font-size: 20px;
    }
  }
}
