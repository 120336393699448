@import '../../../styles/monitor-resolution.scss';

.Organisers {
  width: 100%;
  padding: 20px 0 40px 0;
  background-color: #209bc1;
  color: white;

  &-header {
    font-size: 20px;
    font-weight: bold;
    color: white;

    @media ($mqSmallMobiles) {
      font-size: 26px;
    }

    @media ($mqMobiles) {
      font-size: 32px;
    }
  }

  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-announcementContainer {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    // font-size: 16px;

    // @media ($mqSmallMobiles) {
    //   font-size: 22px;
    // }

    // @media ($mqMobiles) {
    //   font-size: 28px;
    // }
  }

  &-text {
    text-align: left;
    font-size: 12px;

    @media ($mqSmallMobiles) {
      font-size: 16px;
    }

    @media ($mqMobiles) {
      font-size: 20px;
    }
  }

  &-logoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &-logo {
    padding: 0 30px 30px 30px;
    display: inline-block;
    height: 100%;

    &:hover {
      animation: extend 0.2s ease-in 0s 1 normal forwards;
    }

    @media ($mqSmallMobiles) {
      width: 300px;
    }

    @media ($mqMobiles) {
      width: 370px;
    }
  }
}
