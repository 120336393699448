.Header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000000;

  &-bigLogoContainer {
    max-width: 680px;
  }

  &-bigLogo {
    display: inline-block;
    width: 100%;
  }

  &-stickyContainer {
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-fixed {
      position: fixed;
      // top: 0;
      width: 100%;
    }
  }

  &-menuContainer {
    height: 36px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
  }

  &-smallLogoContainer {
    width: 100%;
    padding: 10px 0 0 0;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: white;

    &--hidden {
      display: none;
    }
  }

  &-smallLogo {
    display: inline-block;
    max-width: 230px;
    max-height: 150px;

    pointer-events: none;
  }

  &-languageSwitch {
    margin-right: 30px;
  }

  &-switchIcon {
    padding: 0 7px;
    width: 20px;
  }
}
