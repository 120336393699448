.SiteSwitch {
  height: 35px;
  width: 100%;
  padding: 0 20px;
  background-color: #585858;
  color: #585858;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
