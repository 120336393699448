@import '../../../styles/monitor-resolution.scss';

.GoalCard {
  max-width: 320px;
  min-height: 50px;
  background-color: white;
  border-radius: 15px;
  color: black;
  padding: 20px;
  margin: 20px 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;

  @media (min-width: 800px) {
    min-height: 215px;
  }

  &-id {
    color: #209bc1;
    border-bottom: 5px solid #209bc1;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 28px;

    @media ($mqSmallMobiles) {
      font-size: 34px;
    }

    @media ($mqMobiles) {
      font-size: 40px;
    }
  }

  &-textContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-text {
    padding: 5px;
    font-weight: bold;
    text-align: left;
    font-size: 16px;

    @media ($mqSmallMobiles) {
      font-size: 18px;
    }

    @media ($mqMobiles) {
      font-size: 20px;
    }
  }
}
