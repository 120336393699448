@import '../../../styles/monitor-resolution.scss';

.Menu {
  &-mainPage {
    @media (max-width: 1160px) {
      display: none;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &--notFixed {
      margin-left: 20px;
    }
  }

  &-sideDrawer {
    list-style-type: none;
    display: flex;
    flex-direction: column;
  }

  &-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
  }
}
