@import '../../../styles/monitor-resolution.scss';

.Goals24 {
  width: 100%;
  background-color: #209BC1;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-header {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    color: white;

    @media ($mqSmallMobiles) {
      font-size: 26px;
    }

    @media ($mqMobiles) {
      font-size: 32px;
    }
  }

  &-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &-themes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &-image {
    width: 100%;
  }

  &-contentContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    color: white;

    padding: 20px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 12px;

    @media ($mqSmallMobiles) {
      font-size: 16px;
    }

    @media ($mqMobiles) {
      font-size: 20px;
    }
  }

  &-intention {
    padding: 20px 20px 0 20px;
    max-width: 1150px;
  }
}
