@import '../../../styles/monitor-resolution.scss';

.Registration21 {
  max-width: 1150px;
  padding: 20px 20px 40px 20px;
  background-color: white;
  color: #585858;

  &-header {
    font-size: 20px;
    font-weight: bold;
    color: #585858;

    @media ($mqSmallMobiles) {
      font-size: 26px;
    }

    @media ($mqMobiles) {
      font-size: 32px;
    }
  }

  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-registrationText {
    text-align: left;
    color: #585858;
    font-weight: bold;
    font-size: 12px;

    @media ($mqSmallMobiles) {
      font-size: 16px;
    }

    @media ($mqMobiles) {
      font-size: 20px;
    }
  }

  &-registrationButton {
    text-decoration: none;
    color: inherit;
    border: none;
    position: relative;
    font-size: 19px;
  }

  &-text {
    color: #209bc1;
    font-weight: bold;
    font-size: 18px;

    @media ($mqSmallMobiles) {
      font-size: 24px;
    }

    @media ($mqMobiles) {
      font-size: 30px;
    }
  }

  &-mapContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
  }

  &-map {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  &-mapIconContainer {
    padding: 10px 0 10px 0;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &-iconAnchor {
    padding: 10px;

    & > .material-icons {
      font-size: 32px;
      color: #585858;

      &:hover {
        transform: scale(1.2);
      }

      @media ($mqSmallMobiles) {
        font-size: 40px;
      }

      @media ($mqMobiles) {
        font-size: 48px;
      }
    }
  }

  &-pulsation {
    position: static;
    background: transparent;
    margin: 50px 0 20px 0;
    height: 50px;
    width: 200px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  &-announcementContainer {
    text-decoration: none !important;
    color: #209bc1;
    font-weight: bold;
    font-size: 18px;
    margin: 20px 0;

    @media ($mqSmallMobiles) {
      font-size: 24px;
    }

    @media ($mqMobiles) {
      font-size: 30px;
    }
  }
}
