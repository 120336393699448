.Tabs {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;

  &-list {
    height: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: none;
  }

  &-tabs {
    margin-top: -20px;
    border: solid 2px #585858;
  }
}
