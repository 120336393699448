@import '../../../styles/monitor-resolution.scss';

.Speakers24 {
  width: 100%;
  padding: 20px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;

  &-header {
    color: #209bc1;
    font-size: 20px;
    font-weight: bold;

    @media ($mqSmallMobiles) {
      font-size: 26px;
    }

    @media ($mqMobiles) {
      font-size: 32px;
    }
  }

  &-cards {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-items: center;
    justify-content: center;
  }

  &-announcement {
    color: #209bc1;
    font-size: 12px;
    margin-top: 20px;

    @media ($mqSmallMobiles) {
      font-size: 16px;
    }

    @media ($mqMobiles) {
      font-size: 20px;
    }
  }
}
