.Collapsible23 {
  &-content {
    overflow: hidden;
    background-color: #fff;
    padding: 20px;
    border-radius: 7px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  }

  &-collapse {
    overflow: hidden;
    transition: height 0.3s ease-out;
  }

  &-title {
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #209bc1
  }

  &-listItem {

    &--collapse {
      // overflow: hidden;
      // transition: height 0.3s ease-out;
    }
  }

  &-buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5px;
  }

  &-expandButton {
    width: 25px;
    height: 25px;
    fill: #209bc1;

    &--expanded {
      transform: rotate(180deg);
    }
  }
}
