.PersonalCard23 {
  width: 280px;
  height: fit-content;
  background-color: white;
  color: #585858;
  padding: 20px 20px;
  margin: 20px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 5px solid #585858;
  min-height: 295px;


  &-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &-image {
    display: block;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  &-nameContainer {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
  }

  &-name {
    font-weight: bold;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    background: linear-gradient(90deg, rgba(27,172,226,1) 0%, rgba(88,88,88,1) 62%);
    color: white;
    clip-path: polygon(12% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  &-text {
    display: flex;
    text-align: justify;
  }
}
