@import '../../../styles/monitor-resolution.scss';

.MobileMenuButton {
  padding: 2px;
  margin-left: 30px;
  width: 35px;
  height: 35px;
  background-color: transparent;
  border: solid 3px transparent;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;

  @media (min-width: 1161px) {
    display: none;
  }

  &:hover {
    background-color: #209bc1;
  }

  &:hover > .MobileMenuButton-stripe {
    border-top: solid 3px white;
  }

  &-stripe {
    width: 30px;
    background-color: transparent;
    border-top: solid 3px #209bc1;
  }
}
