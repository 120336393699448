.TabTitle24 {
  padding: 2px;

  &-button {
    background-color: transparent;
    border: solid 2px #585858;
    color: #585858;
    padding: 15px 32px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;

    &:hover {
      color: white;
      background: none;
      border: solid 2px white;
    }

    &:focus {
      outline: none;
    }

    &--clicked {
      color: white;
      border-color: #585858;
      background-color: #585858;

      &:hover {
        color: white;
        border-color: #585858;
        background-color: #585858;
      }
    }
  }
}
