.PersonalCard {
  width: 320px;
  max-height: 350px;
  background-color: white;
  color: #585858;
  padding: 20px 20px;
  margin: 20px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 5px solid #585858;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &-image {
    display: block;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  &-nameContainer {
    width: 100%;
    // padding: 0 30px 0 30px;
  }

  &-name {
    font-weight: bold;
    margin-bottom: 10px;
    color: #585858;
    // border-bottom: 2px solid #c19937;
    // border-bottom: 30px solid #c19937;
  }

  &-text {
    text-align: justify;
  }
}
