@import '../../../styles/monitor-resolution.scss';

.Banner {
  padding: 5px 0;
  width: 100%;
  background-color: #d89d00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  &-link {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:hover .Banner-button {
      border-radius: 50%;
      background-color: white;
      fill: #d89d00;
      cursor: pointer;
    }
  }

  &-text {
    font-size: 16px;
    color: white;
    padding: 0 20px;
  }

  &-button {
    border-radius: 50%;
    margin: 10px 0 10px 0;
    fill: white;
    padding: 3px;

    &:hover {
      border-radius: 50%;
      background-color: white;
      fill: #d89d00;
      cursor: pointer;
    }
  }

  &-collapse {
    overflow: hidden;
    transition: height 0.3s ease-out;
  }

  &-buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &-expandButton {
    width: 30px;
    height: 30x;
    fill: white;

    &--expanded {
      transform: rotate(180deg);
    }
  }

  &-lawConferenceLogo {
    border: white solid 1px;
    display: inline-block;
    width: 160px;
    height: 100%;

    // &:hover {
    //     animation: extend 0.3s ease-in 0s 1 normal forwards;
    // }

    @media ($mqSmallMobiles) {
      width: 220px;
    }

    @media ($mqMobiles) {
      width: 280px;
    }
  }

  &-body {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ($mqMobiles) {
      flex-direction: row;
    }
  }

  &-bodyText {
    display: flex;
    color: white;
    text-align: justify;

    @media ($mqMobiles) {
      text-align: left;
    }
  }

  &-bodyTextContainer {
    padding: 20px;
    width: 282px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
